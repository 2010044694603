import gql from 'graphql-tag'

export const getBrands = gql`subscription getBrands {
    brands(order_by: {id: asc}) {
        id
        image
        name_ar
        name_en
        sender_id
    }
}`

export default {
    getBrands,
}