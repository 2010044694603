import gql from 'graphql-tag'

export const login = gql`query login($username: String!, $password: String!) {
    users(where: {_and: {username: {_eq: $username}, password: {_eq: $password}}}) {
        id,
        username,
        role
    }
}`;

export default {
    login,
}