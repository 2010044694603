import graphql from '@/gql/index'
import queries from '@/gql/queries/categories'
import mutations from '@/gql/mutations/categories'
// import subscriptions from '@/gql/subscriptions/categories'

export default {
    state: {
        all: []
    },
    mutations: {
        categories(state, categories) {
            state.all = categories
        },
    },
    actions: {
        async getCategories({ commit }) {
            const categories = await graphql.query({
                query: queries.getCategories,
            })
            commit('categories', categories.data.categories)
        },
        async addCategory({ commit }, data) {
            await graphql.mutate({
                mutation: mutations.addCategory,
                variables: data
            })
            commit('notification', 'Survey Type successfully added!', { root: true })
        },
        async updateCategory({ commit, dispatch }, data) {
            await graphql.mutate({
                mutation: mutations.updateCategory,
                variables: data
            })
            await dispatch('getCategories')
            commit('notification', 'Survey Type successfully updated!', { root: true })
        }
    }
}

