import gql from 'graphql-tag'

export const addBrand = gql`mutation addBrand($name_en: String!, $name_ar: String!, $image: String!, $sender_id: String!) {
    insert_brands_one(object: {name_en: $name_en, name_ar: $name_ar, image: $image, sender_id: $sender_id}) {
        id
    }
}`

export const updateBrand = gql`mutation updateBrand($name_ar: String!, $name_en: String!, $image: String!, $id: Int!, $sender_id: String!) {
    update_brands_by_pk(pk_columns: {id: $id}, _set: {name_ar: $name_ar, name_en: $name_en, image: $image, sender_id: $sender_id}) {
        id
        name_ar
        name_en
        image
    }
}`

export default {
    addBrand,
    updateBrand,
}