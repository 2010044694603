import graphql from '@/gql/index'
import queries from '@/gql/queries/customers'
import mutations from '@/gql/mutations/customers'
import subscriptions from '@/gql/subscriptions/customers'
import axios from "axios"

export default {
    state: {
        all: []
    },
    mutations: {
        customers(state, customers) {
            state.all = customers
        },
        addCustomer(state, customer) {
            state.all = [...state.all, customer]
        },
        customerSurvey(state, customerSurvey) {
            state.survey = customerSurvey
        },
        checkIfExpired(state, expired) {
            state.survey.expired = expired
        },
    },
    actions: {
        async getCustomers({ commit }) {
            let customers = await graphql.query({
                query: queries.getCustomers,
            })
            commit('customers', customers.data.customers)
        },
        async addSurveyCustomer({ commit }, data) {
            let newCustomer = await graphql.mutate({
                mutation: mutations.addSurveyCustomer,
                variables: data
            })
            commit('addCustomer', newCustomer.data.insert_customers_one)
            commit('notification', 'Customer successfully added!', { root: true })
        },
        async importSurveyCustomers({ commit }, data) {
            axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/import`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                commit('notification', 'Customers successfully imported', { root: true })
            })
        },
        async getCustomerSurvey({ commit }, data) {
            await graphql.subscribe({
                query: subscriptions.getCustomerSurvey,
                variables: data
            }).subscribe((survey) => {
                commit('customerSurvey', survey.data.customers_by_pk)
            })
        },
        async submitSurvey(_, data) {
            data.forEach(async (one) => {
                await graphql.mutate({
                    mutation: mutations.addSurveyAnswer,
                    variables: one
                });
            })
        },
        async checkIfExpired({ commit }, data) {
            axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/checkIfExpired`, data).then((res) => {
                commit('checkIfExpired', res.data.expired)
            })
        }
    }
}

