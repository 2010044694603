import gql from 'graphql-tag'

export const getUsers = gql`subscription getUsers {
    users(order_by: {id: asc}) {
        id
        username
        role
    }
}`

export default {
    getUsers,
}