import graphql from '@/gql/index'
// import queries from '@/gql/queries/brands'
import mutations from '@/gql/mutations/brands'
import subscriptions from '@/gql/subscriptions/brands'
import axios from "axios"

export default {
    state: {
        all: []
    },
    mutations: {
        brands(state, brands) {
            state.all = brands
        },
    },
    actions: {
        async getBrands({ commit }) {
            await graphql.subscribe({
                query: subscriptions.getBrands,
            }).subscribe((brands) => {
                commit('brands', brands.data.brands)
            })
        },
        async addBrand({ commit }, data) {
            await graphql.mutate({
                mutation: mutations.addBrand,
                variables: data
            })
            commit('notification', 'Brand successfully added!', { root: true })
        },
        async updateBrand({ commit, dispatch }, data) {
            await graphql.mutate({
                mutation: mutations.updateBrand,
                variables: data
            })
            await dispatch('getBrands')
            commit('notification', 'Brand successfully updated!', { root: true })
        },
        async uploadImage(_, data) {
            return axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/upload`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                return res
            })
        },
    }
}

