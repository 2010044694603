import { createStore } from 'vuex'
import brands from '@/store/brands'
import categories from '@/store/categories'
import customers from '@/store/customers'
import surveys from '@/store/surveys'
import users from '@/store/users'
import graphql from '@/gql/index'
import queries from '@/gql/queries/settings'
import mutations from '@/gql/mutations/settings'

export default createStore({
  state: {
    notification: ''
  },
  mutations: {
    notification(state, message) {
      state.notification = message
    },
    settings(state, settings) {
      state.settings = settings
    }
  },
  actions: {
    async getSettings({ commit }) {
      let settings = await graphql.query({
        query: queries.getSettings,
      })
      commit('settings', settings.data.settings_by_pk)
    },
    async updateSettings({ commit }, data) {
      await graphql.mutate({
        mutation: mutations.updateSettings,
        variables: data
      })
      commit('settings', data)
      commit('notification', 'Settings successfully saved!')
    },
  },
  modules: {
    brands,
    categories,
    customers,
    surveys,
    users,
  }
})
