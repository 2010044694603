import gql from 'graphql-tag'

export const getCustomersBySurveyId = gql`query getCustomersBySurveyId($id: Int!) {
    customers(where: { survey: { _eq: $id } }) {
        survey
        sent
        language
        id
        customer
    }
}`;

export default {
    getCustomersBySurveyId,
}