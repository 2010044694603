import gql from 'graphql-tag'

export const addSurveyCustomer = gql`mutation addSurveyCustomer($name_en: String!, $name_ar: String!, $mobile: String!, $language: String!, $survey: Int!) {
    insert_customers_one(object: {name_en: $name_en, name_ar: $name_ar, mobile: $mobile, language: $language, survey: $survey, sent: false}) {
        id
        mobile
        name_ar
        name_en,
        language,
        survey
    }
}`

export const addSurveyAnswer = gql`mutation addSurveyAnswer($brand: Int!, $question: Int!, $answer: String!, $survey: Int!, $customer: Int!, ) {
    insert_answers_one(object: {brand: $brand, customer: $customer, question: $question, survey: $survey, answer: $answer}) {
        id
    }
}`

export default {
    addSurveyCustomer,
    addSurveyAnswer
}