import gql from 'graphql-tag'

export const updateSettings = gql`mutation updateSettings($welcome_message_ar: String!, $welcome_message_en: String!, $finished_message_ar: String!, $finished_message_en: String!, $completed_message_ar: String!, $completed_message_en: String!, $expired_message_ar: String!, $expired_message_en: String!, $link_expiry: String!, $sms_message_ar: String!, $sms_message_en: String!, $send_time: String!) {
    update_settings_by_pk(pk_columns: {id: 1}, _set: {welcome_message_ar: $welcome_message_ar, welcome_message_en: $welcome_message_en, finished_message_ar: $finished_message_ar, finished_message_en: $finished_message_en, completed_message_ar: $completed_message_ar, completed_message_en: $completed_message_en, expired_message_ar: $expired_message_ar, expired_message_en: $expired_message_en, link_expiry: $link_expiry, send_time: $send_time, sms_message_ar: $sms_message_ar, sms_message_en: $sms_message_en}) {
        finished_message_ar
        finished_message_en
        completed_message_ar
        completed_message_en
        welcome_message_ar
        welcome_message_en
        expired_message_ar
        expired_message_en
        welcome_message_ar
        welcome_message_en
        link_expiry
        send_time
        sms_message_ar
        sms_message_en
        id
    }
}`

export default {
    updateSettings,
}