import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/pages/Home.vue';
import auth from '@/utils/auth';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/survey/:id/:customerId',
        name: 'Survey',
        component: () => import(/* webpackChunkName: "customer" */ '@/pages/customer/Survey.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '@/pages/Login.vue'),
    },
    {
        path: '/admin',
        name: 'DashboardAlternativePage',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/Dashboard.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/admin/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/Dashboard.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/admin/brands',
        name: 'BrandsList',
        component: () => import(/* webpackChunkName: "brands.list" */ '@/pages/brands/List.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/admin/brands/add',
        name: 'AddBrand',
        component: () => import(/* webpackChunkName: "brands.add" */ '@/pages/brands/Add.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/admin/brands/edit/:id',
        name: 'EditBrand',
        component: () => import(/* webpackChunkName: "brands.edit" */ '@/pages/brands/Add.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/admin/categories',
        name: 'CategoriesList',
        component: () => import(/* webpackChunkName: "categories.list" */ '@/pages/categories/List.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/admin/categories/add/:id?',
        name: 'AddCategory',
        component: () => import(/* webpackChunkName: "categories.edit" */ '@/pages/categories/Add.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/admin/customers',
        name: 'CustomersList',
        component: () => import(/* webpackChunkName: "customers.list" */ '@/pages/customers/List.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/admin/customers/add',
        name: 'AddCustomer',
        component: () => import(/* webpackChunkName: "customers.add" */ '@/pages/customers/Add.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/admin/customers/import',
        name: 'ImportCustomers',
        component: () => import(/* webpackChunkName: "customers.import" */ '@/pages/customers/Import.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/admin/surveys',
        name: 'SurveysList',
        component: () => import(/* webpackChunkName: "surveys.list" */ '@/pages/surveys/List.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/admin/surveys/edit/:id',
        name: 'EditSurvey',
        component: () => import(/* webpackChunkName: "surveys.edit" */ '@/pages/surveys/Edit.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/admin/surveys/:id',
        name: 'ViewSurvey',
        component: () => import(/* webpackChunkName: "surveys.view" */ '@/pages/surveys/View.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/admin/surveys/add/:id?',
        name: 'AddSurvey',
        component: () => import(/* webpackChunkName: "surveys.add" */ '@/pages/surveys/Add.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/admin/users',
        name: 'UsersList',
        component: () => import(/* webpackChunkName: "users.list" */ '@/pages/users/List.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/admin/users/add',
        name: 'AddUser',
        component: () => import(/* webpackChunkName: "users.add" */ '@/pages/users/Add.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/admin/users/edit/:id',
        name: 'EditUser',
        component: () => import(/* webpackChunkName: "users.edit" */ '@/pages/users/Edit.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/admin/settings',
        name: 'EditSettings',
        component: () => import(/* webpackChunkName: "settings" */ '@/pages/settings/Update.vue'),
        meta: {
            auth: true,
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, _, next) => {
    if (to.meta.auth) {
        if (!auth.checkAuth()) {
            next();
            router.push('/login');
            return;
        }
    }
    next();
});

export default router;
