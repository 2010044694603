export default {
    logout() {
        localStorage.removeItem("usr")
    },

    login(data) {
        localStorage.setItem("usr", JSON.stringify(data))
    },

    checkAuth() {
        let usr = localStorage.getItem("usr")
        return (usr) ? true : false
    },
};
