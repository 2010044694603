import graphql from '@/gql/index'
import mutations from '@/gql/mutations/users'
import queries from '@/gql/queries/users'
import subscriptions from '@/gql/subscriptions/users'
import auth from '@/utils/auth'

export default {
    state: {
        all: [],
        user: JSON.parse(localStorage.getItem("usr")) || null,
    },
    mutations: {
        users(state, users) {
            state.all = users
        },
        login(state, user) {
            auth.login(user)
            state.user = user
        },
        logout(state) {
            state.user = null
            auth.logout()
        },

    },
    actions: {
        async getUsers({ commit }) {
            await graphql.subscribe({
                query: subscriptions.getUsers,
            }).subscribe((users) => {
                commit('users', users.data.users)
            })
        },
        async addUser({ commit }, data) {
            await graphql.mutate({
                mutation: mutations.addUser,
                variables: data
            })
            commit('notification', 'User successfully added!', { root: true })
        },
        async editUser({ commit }, { id, username, password, role }) {
            await graphql.mutate({
                mutation: mutations.updateUser,
                variables: {
                    id,
                    username,
                    password,
                    role
                }
            })
            commit('notification', 'User successfully updated!', { root: true })
        },
        async deleteUser({ commit }, id) {
            await graphql.mutate({
                mutation: mutations.deleteUser,
                variables: {
                    id
                }
            })
            commit('notification', 'User successfully deleted!', { root: true })
        },
        async login({ commit }, data) {
            let usr = await graphql.query({
                query: queries.login,
                variables: data
            })
            if (usr.data.users.length) {
                commit('login', usr.data.users[0])
            }
        }
    }
}

