import gql from 'graphql-tag'

export const getSettings = gql`query getSettings {
    settings_by_pk(id: 1) {
        completed_message_ar
        completed_message_en
        expired_message_ar
        expired_message_en
        finished_message_ar
        finished_message_en
        welcome_message_ar
        welcome_message_en
        link_expiry
        send_time
        sms_message_ar
        sms_message_en
        id
    }
}`

export default {
    getSettings,
}