import graphql from '@/gql/index'
import mutations from '@/gql/mutations/surveys'
import subscriptions from '@/gql/subscriptions/surveys'
import queries from '@/gql/queries/surveys'

export default {
    state: {
        all: [],
        survey: {},
        customerSurvey: {},
    },
    mutations: {
        surveys(state, surveys) {
            state.all = surveys
        },
        survey(state, survey) {
            state.survey[survey.id] = survey
        },
    },
    actions: {
        async getSurveys({ commit }) {
            await graphql.subscribe({
                query: subscriptions.getSurveys,
            }).subscribe((surveys) => {
                commit('surveys', surveys.data.surveys)
            })
        },
        async getSurveyById({ commit }, data) {
            let survey = await graphql.query({
                query: queries.getSurveyById,
                variables: data
            })
            commit('survey', survey.data.surveys_by_pk)
        },
        async addSurvey({ commit }, data) {
            let survey = await graphql.mutate({
                mutation: mutations.addSurvey,
                variables: data.survey
            });
            if (data.questions.value.length) {
                data.questions.value.forEach(async (one, index) => {
                    await graphql.mutate({
                        mutation: mutations.addSurveyQuestion,
                        variables: {
                            ...one,
                            survey: survey.data.insert_surveys_one.id,
                            brand: data.survey.brand,
                            order: one.order || (index + 1)
                        }
                    });
                })
            }
            commit('notification', 'Survey successfully added!', { root: true })
        },
        async updateSurvey({ commit }, data) {
            await graphql.mutate({
                mutation: mutations.updateSurvey,
                variables: data
            });
            commit('notification', 'Survey successfully updated!', { root: true })
        },
        async updateQuestion({ commit }, data) {
            await graphql.mutate({
                mutation: mutations.updateSurveyQuestion,
                variables: {
                    ...data
                }
            })
            commit('notification', 'Question successfully updated!', { root: true })
        },
        async updateQuestionsOrder({ commit }, questions) {
            questions.forEach(async q => {
                await graphql.mutate({
                    mutation: mutations.updateQuestionOrder,
                    variables: {
                        id: q.id,
                        order: q.order
                    }
                })
            })
            commit('notification', 'Questions order successfully updated!', { root: true })
        },
        async deleteQuestion({ commit }, id) {
            await graphql.mutate({
                mutation: mutations.deleteQuestionWithAnswers,
                variables: {
                    id
                }
            })
            commit('notification', 'Question successfully deleted!', { root: true })
        },
        async deleteSurvey({ commit }, id) {
            await graphql.mutate({
                mutation: mutations.deleteSurvey,
                variables: {
                    id
                }
            })
            commit('notification', 'Survey successfully deleted!', { root: true })
        },
    }
}

