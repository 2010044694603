import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'

// HTTP connection to the API
const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: `https:${process.env.VUE_APP_GRAPHQL_LINK}`,
    headers: {
        'content-type': 'application/json',
        'x-hasura-admin-secret': process.env.VUE_APP_HASURA_SECRET,
        // 'authorization': token ? `Bearer ${token}` : ''
    }
})

// Create the subscription websocket link
const wsLink = new WebSocketLink({
    uri: `wss:${process.env.VUE_APP_GRAPHQL_LINK}`,
    options: {
        lazy: true,
        reconnect: true,
        connectionParams: async () => {
            return {
                headers: {
                    'content-type': 'application/json',
                    'x-hasura-admin-secret': process.env.VUE_APP_HASURA_SECRET,
                    // 'authorization': token ? `Bearer ${token}` : ''
                }
            }
        },
    },
})

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
    // split based on operation type
    ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
    },
    wsLink,
    httpLink
)


// Cache implementation
const cache = new InMemoryCache()
const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
}
// Create the apollo client
const apolloClient = new ApolloClient({
    link,
    cache,
    defaultOptions: defaultOptions,
    connectToDevTools: true,
})

export default apolloClient