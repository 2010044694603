import gql from 'graphql-tag'

export const getCategories = gql`query getCategories {
    categories(order_by: {id: asc}) {
        id
        name_ar
        name_en
    }
}`

export default {
    getCategories,
}