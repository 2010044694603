import gql from 'graphql-tag'

export const addSurvey = gql`mutation addSurvey($welcome_message_ar: String!, $welcome_message_en: String!, $finished_message_ar: String!, $finished_message_en: String!, $brand: Int!, $completed_message_ar: String!, $completed_message_en: String!, $expired_message_ar: String!, $expired_message_en: String!, $link_expiry: String!, $name_ar: String!, $name_en: String!, $public: Boolean!, $sms_message_ar: String!, $sms_message_en: String!, $send_time: String! $category: Int!) {
    insert_surveys_one(object: {welcome_message_ar: $welcome_message_ar, welcome_message_en: $welcome_message_en, finished_message_ar: $finished_message_ar, finished_message_en: $finished_message_en, brand: $brand, completed_message_ar: $completed_message_ar, completed_message_en: $completed_message_en, expired_message_ar: $expired_message_ar, expired_message_en: $expired_message_en, link_expiry: $link_expiry, name_ar: $name_ar, name_en: $name_en, public: $public, sms_message_ar: $sms_message_ar, sms_message_en: $sms_message_en, send_time: $send_time, category: $category}) {
        id
    }
}`

export const addSurveyQuestion = gql`mutation addSurveyQuestion($brand: Int!, $question_ar: String!, $question_en: String!, $survey: Int!, $order: Int!, $type: String!, $good_en: String!, $good_ar: String!, $bad_en: String!, $bad_ar: String!) {
    insert_questions_one(object: {brand: $brand, order: $order, question_ar: $question_ar, question_en: $question_en, survey: $survey, type: $type, good_en: $good_en, good_ar: $good_ar, bad_en: $bad_en, bad_ar: $bad_ar}) {
        id
    }
}`

export const updateSurvey = gql`mutation updateSurvey($id: Int!, $brand: Int!, $category: Int!, $completed_message_ar: String!, $completed_message_en: String!, $expired_message_ar: String!, $expired_message_en: String!, $finished_message_en: String!, $finished_message_ar: String!, $link_expiry: String!, $name_ar: String!, $name_en: String!, $public: Boolean = false, $sms_message_ar: String!, $sms_message_en: String!, $welcome_message_ar: String!, $welcome_message_en: String!) {
    update_surveys_by_pk(pk_columns: {id: $id}, _set: {brand: $brand, category: $category, completed_message_ar: $completed_message_ar, completed_message_en: $completed_message_en, expired_message_ar: $expired_message_ar, expired_message_en: $expired_message_en, finished_message_en: $finished_message_en, finished_message_ar: $finished_message_ar, link_expiry: $link_expiry, name_ar: $name_ar, name_en: $name_en, public: $public, sms_message_ar: $sms_message_ar, sms_message_en: $sms_message_en, welcome_message_ar: $welcome_message_ar, welcome_message_en: $welcome_message_en}) {
        id
    }
}`

export const deleteQuestionWithAnswers = gql`mutation deleteQuetion($id: Int!) {
    delete_questions_by_pk(id: $id) {
        id
    }
    delete_answers(where: {question: {_eq: $id}}) {
        affected_rows
    }
}`

export const updateSurveyQuestion = gql`mutation updateSurveyQuestion($id: Int!, $bad_ar: String = "", $bad_en: String = "", $good_ar: String = "", $good_en: String = "", $question_ar: String!, $question_en: String!) {
    update_questions_by_pk(pk_columns: {id: $id}, _set: {bad_ar: $bad_ar, bad_en: $bad_en, good_ar: $good_ar, good_en: $good_en, question_ar: $question_ar, question_en: $question_en}) {
        id
    }
}`

export const updateQuestionOrder = gql`mutation updateSurveyQuestionOrder($id: Int!, $order: Int!) {
    update_questions_by_pk(pk_columns: {id: $id}, _set: {order: $order}) {
        id
    }
}`

export const deleteSurvey = gql`mutation deleteSurvey($id: Int!) {
    delete_answers(where: {survey: {_eq: $id}}) {
        returning {
            id
        }
    }
    delete_questions(where: {survey: {_eq: $id}}) {
        returning {
            id
        }
    }
    delete_customers(where: {survey: {_eq: $id}}) {
        returning {
            id
        }
    }
    delete_surveys_by_pk(id: $id) {
        id
    }
}`

export default {
    addSurvey,
    addSurveyQuestion,
    updateSurvey,
    updateSurveyQuestion,
    updateQuestionOrder,
    deleteQuestionWithAnswers,
    deleteSurvey,
}