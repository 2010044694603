import gql from 'graphql-tag'

export const addCategory = gql`mutation addCategory($name_en: String!, $name_ar: String!) {
    insert_categories_one(object: {name_en: $name_en, name_ar: $name_ar}) {
        id
    }
}`

export const updateCategory = gql`mutation UpdateCategory($name_ar: String!, $name_en: String!, $id: Int!) {
    update_categories_by_pk(pk_columns: {id: $id}, _set: {name_ar: $name_ar, name_en: $name_en}) {
        id
        name_ar
        name_en
    }
}`

export default {
    addCategory,
    updateCategory,
}