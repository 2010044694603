<template>
  <div class="home">
    HOME
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'HomePage',
  components: {
  }
}
</script>
