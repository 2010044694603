import gql from 'graphql-tag'

export const addUser = gql`mutation addUser($username: String!, $password: String!, $role: String!) {
    insert_users_one(object: {username: $username, password: $password, role: $role}) {
        id
    }
}`

export const updateUser = gql`mutation updateUser($role: String!, $username: String!, $id: Int!) {
    update_users_by_pk(pk_columns: {id: $id}, _set: {role: $role, username: $username}) {
        id
    }
}`

export const deleteUser = gql`mutation deleteUser($id: Int!) {
    delete_users_by_pk(id: $id) {
        id
    }
}`

export default {
    addUser,
    updateUser,
    deleteUser,
}