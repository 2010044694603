import gql from 'graphql-tag'

export const getCustomerSurvey = gql`subscription getCustomerSurvey($surveyId: Int!, $customerId: Int!) {
    customers_by_pk(id: $customerId) {
        language
        name_ar
        name_en
        updated_at
        surveys {
            id
            name_en
            name_ar
            expired_message_en
            expired_message_ar
            completed_message_en
            completed_message_ar
            welcome_message_ar
            welcome_message_en
            finished_message_ar
            finished_message_en
            link_expiry
            public
            brands {
                id
                image
                name_ar
                name_en
            }
            categories {
                id
                name_ar
                name_en
            }
            questions(where: {survey: {_eq: $surveyId}}, order_by: {order: asc}) {
                id
                order
                question_ar
                question_en
                type
                good_en
                good_ar
                bad_en
                bad_ar
            }
            answers(where: {survey: {_eq: $surveyId}, _and: {customer: {_eq: $customerId}}}) {
                id
                answer
                custom
                question
                created_at
            }
        }    
    }
}`

export default {
    getCustomerSurvey,
}