import {createApp} from 'vue';
import App from '@/App.vue';
import '@/registerServiceWorker';
import router from '@/router';
import store from '@/store';
import emitter from '@/plugins/emitter';
import crypto from '@/plugins/crypto';
import './assets/index.css';

const app = createApp(App);
app.use(emitter);
app.use(crypto);
app.use(store);
app.use(router);
app.mount('#app');

export default app;
