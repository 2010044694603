import gql from "graphql-tag";

export const getSurveyById = gql`
  query getSurveyById($id: Int!) {
    surveys_by_pk(id: $id) {
      id
      link_expiry
      send_time
      name_ar
      name_en
      public
      sms_message_ar
      sms_message_en
      completed_message_ar
      completed_message_en
      expired_message_ar
      expired_message_en
      finished_message_ar
      finished_message_en
      welcome_message_ar
      welcome_message_en
      brands {
        name_ar
        name_en
        image
        id
      }
      categories {
        id
        name_ar
        name_en
      }
      questions(order_by: { order: asc }) {
        id
        order
        question_ar
        question_en
        good_en
        good_ar
        bad_en
        bad_ar
        type
      }
      customers(limit: 1000, order_by: { updated_at: desc }) {
        id
        language
        mobile
        name_ar
        name_en
        sent
        updated_at
        answers(where: { survey: { _eq: $id } }) {
          answer
          custom
          customer
          id
          created_at
          question
        }
      }
    }
  }
`;

export default {
  getSurveyById,
};
